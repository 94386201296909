import React from 'react'
import { Link } from 'gatsby';

const addModifiers = (base = '', modifiers = []) => {
  const mods = Array.isArray(modifiers) ? modifiers : [modifiers];
  return mods.map((mod => `${base}--${mod}`)).join(' ');
}

const Button = ({ theme, disabled = false, children, href, onClick, type, to, target }) => {
  const classes = `button ${addModifiers('button', theme)} ${disabled ? 'button--is-disabled' : ''}`;

  if (typeof to !== 'undefined') {
    return (<Link to={to} onClick={onClick} className={classes}>
      {children}
    </Link>);
  }

  if (typeof href !== 'undefined') {
    return (<a href={href} onClick={onClick} className={classes} target={target}>
      {children}
    </a>);
  }

  return (<button type={type} onClick={onClick} className={classes} disabled={disabled}>
    {children}
  </button>);
};

export default Button
