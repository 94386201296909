/* eslint-disable no-magic-numbers */
/**
 *  Service: locations
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const ROUTE = '/api/locations';

export const locationsLoad = () => request(ROUTE);

// Create a Context
const LocationsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  // Ensure data is an array.
  const values = Array.isArray(data)
    ? data
    : Object.values(data);

  return values.map(item => ({
    id: item.id,
    label: item.label
  }));
}

const getLocationFromResponse = (id, response) => {
  const data = getDataFromResponse(response);
  return data[id];
}

const getLocationTimezoneOffsetFromResponse = (id, response) => {
  const location = getLocationFromResponse(id, response);

  return location
    // Convert offset from seconds to minutes as that is what JS expects
    ? location.timezone_offset / 60
    : 0;
}

const getLocationTimezoneFromResponse = (id, response) => {
  const location = getLocationFromResponse(id, response);

  return location
    // Convert offset from seconds to minutes as that is what JS expects
    ? location.timezone
    : 'America/New_York';
}

export const LocationsAPIProvider = ({ children }) => {
  const loader = useLoads('locations', locationsLoad, {
    context: 'locations',
    defer: true,
    loadPolicy: 'cache-and-load'
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  // Location key / value pa
  const options = getOptionsFromResponse(loader.response);

  return (
    <LocationsAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options,
      getTimezoneOffset: (id) => getLocationTimezoneOffsetFromResponse(id, loader.response),
      getTimezone: (id) => getLocationTimezoneFromResponse(id, loader.response)
    })}>
      {children}
    </LocationsAPIContext.Provider>
  )
}

LocationsAPIProvider.propTypes = {
  children: PropTypes.element
}

export default LocationsAPIContext
