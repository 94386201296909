/**
 *  Context: messages
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Create a Context
const MessagesContext = React.createContext();

export const MessagesProvider = ({ children }) => {
  const [
    messages,
    setMessages
  ] = useState([]);

  const clearMessages = () => {
    setMessages([]);
  };

  const value = {
    clearMessages,
    messages,
    setMessages
  }

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  )
}

MessagesProvider.propTypes = {
  children: PropTypes.element
}

export default MessagesContext
