/**
 *  Service: user
 */

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'
import LocationContext from '../context/location';

const ROUTE = '/api/user';

export const userLoad = () => request(ROUTE);

// Create a Context
const UserAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const UserAPIProvider = ({ children }) => {
  const loader = useLoads('user', userLoad, {
    context: 'user',
    defer: true,
    loadPolicy: 'cache-and-load'
  });

  const location = useContext(LocationContext);

  // Raw response data
  const data = getDataFromResponse(loader.response);

  // Does user have the role?
  const hasRole = role => data.role && data.role.indexOf(role) >= 0;

  // Does user have an role in the passed array
  const hasOneOfRoles = roles => roles.some(hasRole);

  useEffect(() => {
    if (data && data.location && data.location.id) {
      location.setId(data.location.id)
    }
  });

  return (
    <UserAPIContext.Provider value={Object.assign({}, loader, {
      data,
      hasRole,
      hasOneOfRoles
    })}>
      {children}
    </UserAPIContext.Provider>
  )
}

UserAPIProvider.propTypes = {
  children: PropTypes.element
}

export default UserAPIContext
