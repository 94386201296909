/**
 * Returns the clientId from the url.
 * @param {string} url
 *
 * @returns {string}
 *   The client ID or undefined
 */
export const parseId = url => {
  const id = url.match(/\d+/)

  return Array.isArray(id)
    ? id[0]
    : id;
}
