import React, { useState, useContext, useEffect } from 'react'
import { navigate } from 'gatsby';
import { globalHistory } from "@reach/router"

import Card from '../card'
import Messages from './../messages'
import AuthContext from './authContext'
import LoadingIndicator from '../LoadingIndicator'
import { parseId } from '../../utils/path';

const LoginWithAuthCode = ({code, redirect_uri}) => {
  const authContext = useContext(AuthContext);
  const { loginWithAuthCode } = authContext;

  const [
    messages,
    setMessages
  ] = useState([]);

  const [
    submitting,
    setSubmitting
  ] = useState(true);

  if (messages.length > 0) {
    const dest = globalHistory.location.pathname.indexOf('client-info') >= 0
      ? `/resend/client-info/${parseId(window.location.pathname)}`
      : `/resend/report`;

    navigate(dest, { state: { messages } });
  }

  useEffect(() => {
    loginWithAuthCode(code, redirect_uri)
      .then(() => {
        navigate(redirect_uri.replace(globalHistory.location.origin, ''));
      })
      .catch(error => {
        setMessages([
          {
            type: 'error',
            text: error.response.data.message
          }
        ]);
        setSubmitting(false)
      })
  }, []);

  return (
    <div className="container-panel">
      <Card>
        <div className="card--inner">
          { submitting
            ? <LoadingIndicator />
            : <Messages messages={messages} />
          }
        </div>
      </Card>
    </div>
  )
}

export default LoginWithAuthCode;
