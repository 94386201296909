import React from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = ({ show = true, children, fullScreen, fixed, passThru, text = 'Loading' }) => (
  <>
    { show
      ? <div className={`loading ${fullScreen
        ? 'loading--fullscreen'
        : ''} ${fixed
        ? 'loading--fixed'
        : ''} ${passThru
        ? 'loading--passthru'
        : ''}`}
      >
        <div className="loading__icon"></div>
        <p className="loading__text">{text}</p>
        {passThru && <div className={"loading__children"}>{children}</div>}
      </div>
      : children
    }
  </>
);

LoadingIndicator.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.element,
  fullScreen: PropTypes.bool,
  fixed: PropTypes.bool,
  passThru: PropTypes.bool,
  text: PropTypes.string
};

export default LoadingIndicator;
