import React from 'react'

const SvgIcon = ({ label, width, height, children }) => (
  <svg
    className={`icon--${label}`}
    width={width}
    height={height}
    // viewBox={`0 0 24 24`}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    { children }
  </svg>
)

export default SvgIcon
