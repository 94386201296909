/**
 * This provides functionality for:
 *  checking access
 *  logging the user in with username / password
 *  logging the user out (should this state be handled here or externally?)
 */
import React, { Component } from 'react'

const AuthContext = React.createContext({})

import { fetchToken, loginData, loginAuthCodeData, logoutOauth, checkAccess, getToken, subscribe } from '../../lib/oauth'
import { responseIsOK } from '../../lib/request'

class AuthProvider extends Component {

  constructor() {
    super()

    this.state = {
      isAuth: null,
    }
  }

  componentDidMount() {
    subscribe('logout', this.logout);

    getToken()
      .then(() => this.setState({isAuth: true}))
      .catch(() => this.setState({isAuth: false}));
  }

  /**
   * Authenticates the user with username and password.
   */
  login = (username, password) => {
    return fetchToken(loginData(username, password))
      .then(response => {
        console.log('fetchToken Resolved', response);

        if (responseIsOK(response)) {
          const isAuth = checkAccess();
          this.setState({ isAuth })
        }

        return response;
      })
  }

  /**
   * Authenticates the user with authorization Code.
   */
  loginWithAuthCode = (code, redirect_uri) => {
    return fetchToken(loginAuthCodeData(code, redirect_uri))
      .then(response => {
        console.log('fetchToken Resolved', response);

        if (responseIsOK(response)) {
          const isAuth = checkAccess();
          this.setState({ isAuth })
        }

        return response;
      })
  }

  /**
   * Logs the user out.
   */
  logout = () => {
    logoutOauth()
      .then(() => {
        this.setState({isAuth: checkAccess()})
      })
  }

  /**
   * Gets the string equivilant of isAuth state.
   *
   * @memberof AuthProvider
   */
  getAuthState = (isAuth) => {
    switch (isAuth) {
      case null:
        return 'isPending'
      case true:
        return 'isTrue'
      default:
        return 'isFalse'
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          authState: this.getAuthState(this.state.isAuth),
          login: this.login,
          loginWithAuthCode: this.loginWithAuthCode,
          logout: this.logout,
          user: this.state.user,
          loadUser: this.loadUser
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
export default AuthContext;
