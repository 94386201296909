/**
 *  Context: location
 */

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocationsAPIContext from '../services/locations';

// Create a Context
const LocationContext = React.createContext();

export const LocationProvider = ({ id = null, children }) => {
  const locationsAPI = useContext(LocationsAPIContext);

  // Similar to componentDidMount
  useEffect(() => {
    locationsAPI.load();
  }, []);

  const [
    location,
    setLocation
  ] = useState(id);

  const value = {
    id: location,
    setId: setLocation,
    timezoneOffset: (loc = location) => locationsAPI.getTimezoneOffset(loc),
    timezone: (loc = location) => locationsAPI.getTimezone(loc)
  }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}

LocationProvider.propTypes = {
  id: PropTypes.string,
  children: PropTypes.element
}

export default LocationContext
