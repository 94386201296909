import React from 'react'

export const plus = theme => (
  <path
    fill={
      (theme === 'primary')
        ? '#C4E538'
        : '#A3CB38'
    }
    stroke={
      (theme === 'primary')
        ? '#51AF35'
        : '#FFF'
    }
    strokeWidth="2"
    fillRule="nonzero"
    className="icon__fill--secondary"
    d="M14,10 L17,10 C18.1045695,10 19,10.8954305 19,12 C19,13.1045695 18.1045695,14 17,14 L14,14 L14,17 C14,18.1045695 13.1045695,19 12,19 C10.8954305,19 10,18.1045695 10,17 L10,14 L7,14 C5.8954305,14 5,13.1045695 5,12 C5,10.8954305 5.8954305,10 7,10 L10,10 L10,7 C10,5.8954305 10.8954305,5 12,5 C13.1045695,5 14,5.8954305 14,7 L14,10 Z"
  />
)

export const remove = theme => (
  <path
    fill={
      (theme === 'primary')
        ? '#C4E538'
        : '#EA2027'
    }
    stroke={
      (theme === 'primary')
        ? '#51AF35'
        : '#FFF'
    }
    strokeWidth="2"
    fillRule="nonzero"
    transform="translate(12,-5) rotate(45)"
    d="M14,10 L17,10 C18.1045695,10 19,10.8954305 19,12 C19,13.1045695 18.1045695,14 17,14 L14,14 L14,17 C14,18.1045695 13.1045695,19 12,19 C10.8954305,19 10,18.1045695 10,17 L10,14 L7,14 C5.8954305,14 5,13.1045695 5,12 C5,10.8954305 5.8954305,10 7,10 L10,10 L10,7 C10,5.8954305 10.8954305,5 12,5 C13.1045695,5 14,5.8954305 14,7 L14,10 Z"
  />
)

export const swap = theme => (
  <path
    fill={
      (theme === 'primary')
        ? '#C4E538'
        : '#A3CB38'
    }
    stroke={
      (theme === 'primary')
        ? '#51AF35'
        : '#FFF'
    }
    strokeWidth="2"
    fillRule="nonzero"
    d="M13.5,12 L10,12 L10,7 L13.5,7 L13.5,3.58578644 L19.4142136,9.5 L13.5,15.4142136 L13.5,12 Z M10.5,12 L14,12 L14,17 L10.5,17 L10.5,20.4142136 L4.58578644,14.5 L10.5,8.58578644 L10.5,12 Z"
  />
)

export const next = theme => {
  return (
    <path
      fill={
      (theme === 'primary')
          ? '#C4E538'
          : '#A3CB38'
      }
      stroke={
        (theme === 'primary')
          ? '#51AF35'
          : '#FFF'
      }
      strokeWidth="1"
      fillRule="nonzero"
      className="icon__fill--secondary"
      d="M13.379 13.5H7a1.5 1.5 0 0 1 0-3h6.379l-1.44-1.44a1.5 1.5 0 1 1 2.122-2.12l4 4a1.499 1.499 0 0 1 0 2.12l-4 4a1.497 1.497 0 0 1-2.121 0 1.499 1.499 0 0 1 0-2.12l1.439-1.44z" />
  )
}


// export const checkmark = theme => (
//   <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//     <g transform="translate(-4.000000, -5.000000)"
//       fill={
//         (theme === 'primary')
//           ? '#C4E538'
//           : '#A3CB38'
//       }
//       fillRule="nonzero"
//       stroke="#F9FBFD"
//       strokeWidth="2">
//         <path d="M10.5882353,11.8346363 L15.682024,6.74084755 C16.6698208,5.75305082 18.2713557,5.75305082 19.2591524,6.74084755 C20.2469492,7.72864429 20.2469492,9.33017924 19.2591524,10.317976 L12.3767995,17.2003289 C11.3890028,18.1881257 9.78746782,18.1881257 8.79967108,17.2003289 L5.74084755,14.1415054 C4.75305082,13.1537086 4.75305082,11.5521737 5.74084755,10.564377 C6.72864429,9.57658023 8.33017924,9.57658023 9.31797598,10.564377 L10.5882353,11.8346363 Z" id="Page-1"></path>
//     </g>
//   </g>
// )
