/* eslint-disable max-statements */
/* eslint-disable no-process-env */
/* eslint-disable camelcase */
/* eslint-disable dot-location */
import axios from 'axios'
import Cookies from 'universal-cookie'
import { window } from 'browser-monads'
import { responseIsOK } from './request'
import { routes, parseTempAccess, getUrl } from './routes'
import get from 'lodash/get';

// The number of seconds before the token expires before refreshing it.
const TOKEN_REFRESH_THRESHOLD = 30;

// The number of milliseconds to debounce fetch requests;
const FETCH_DEBOUNCE = 50;

const TOKEN_HEADERS = {
  // 'Content-Type': 'multipart/form-data',
  Accept: 'application/json'
}

const SCOPES = 'accountant administrator client lab_director researcher ta temporary_client';

const cookies = new Cookies();

// A tokenRefresher placeholder for setTimeout()
// This is used to set and clear timeouts related to automatically
// refreshing tokens.
let tokenRefresher = {};
let fetching = false;

export const expiresAt = expiresIn => Date.now() + (expiresIn * 1000);

export const getAccessToken = () => {
  return cookies.get('accessToken')
};

export const setAccessToken = (token, maxAge) => cookies.set(
  'accessToken',
  token,
  {
    maxAge,
    path: '/'
  }
);

export const checkAccess = () => Boolean(getAccessToken());

export const getRefreshToken = () => cookies.get('refreshToken');
export const setRefreshToken = token => cookies.set(
  'refreshToken',
  token,
  {
    path: '/'
  }
);

export const removeAccessToken = () => cookies.remove('accessToken', { path: '/' });
export const removeRefreshToken = () => cookies.remove('refreshToken', { path: '/' });

// @todo check for a valid "token"
export const validateTempAccess = token => (token !== null && typeof token !== 'undefined' && token !== '');

export const getTempAccess = () => cookies.get('tempAccessToken')
export const setTempAccess = token => cookies.set('tempAccessToken', token)
export const removeTempAccess = () => cookies.remove('tempAccessToken')

export const loginData = (username, password) => (
  {
    grant_type: "password",
    client_id: process.env.OAUTH_CLIENT_ID,
    username,
    password,
    scope: SCOPES,
  }
)

export const loginAuthCodeData = (code, redirect_uri) => (
  {
    grant_type: "authorization_code",
    client_id: process.env.OAUTH_AUTH_CODE_CLIENT_ID,
    code,
    redirect_uri,
    scope: SCOPES,
  }
)

export const refreshData = refreshToken => {
  const client_id = refreshToken.grant_type === 'authorization_code'
    ? process.env.OAUTH_AUTH_CODE_CLIENT_ID
    : process.env.OAUTH_CLIENT_ID;

  const refresh_token = typeof(refreshToken) === 'string'
    ? refreshToken
    : refreshToken.refresh_token;

  return {
    grant_type: "refresh_token",
    client_id,
    // client_secret: process.env.OAUTH_CLIENT_SECRET,
    refresh_token
  }
};


export const loginSetData = data => {
  const bodyFormData = new FormData()

  for (const param in data) {
    if (data.hasOwnProperty(param)) {
      bodyFormData.append(param, data[param])
    }
  }

  return bodyFormData
}

export const logoutOauth = () => {
  // Clear any attempts to refresh
  clearTimeout(tokenRefresher);
  // Clear locally saved data
  window.jocrfStore.clearAll();

  // Remove saved tokens
  return Promise.all([
    removeAccessToken(),
    removeRefreshToken()
  ]);
}

/**
 * Fetch a token from the server using username and password
 */
export const fetchToken = data => {
  const url = getUrl(routes.token);
  const body = loginSetData(data);

  return new Promise((resolve, reject) => {
    axios.post(url, body, {
      headers: TOKEN_HEADERS
    })
      .then(res => {
        return resolve(handleTokenResponseSuccess(res, body.get('grant_type')));
      })
      .catch(res => {
        handleTokenResponseError(res);
        reject(res);
      });
  })
}

let pendingTokenRequest = null;

/**
 * Fetch a token from the server using refreshToken
 */
export const fetchRefreshToken = refreshToken => {
  const url = getUrl(routes.token);
  const body = loginSetData(refreshData(refreshToken));

  let tokenRequest;

  if (pendingTokenRequest !== null) {
    tokenRequest = pendingTokenRequest;
  } else {
    tokenRequest = axios.post(url, body,
      {
        headers: TOKEN_HEADERS
      })
      .then((res) => handleTokenResponseSuccess(res, refreshToken.grant_type))
      .catch(handleRefreshTokenResponseError);
    pendingTokenRequest = tokenRequest;
  }

  return tokenRequest;
}

export const handleTokenResponseSuccess = (response, grant_type) => {
  if (responseIsOK(response) && response.data.access_token && response.data.refresh_token) {
    // Prevent tokenRefresher from firing.
    clearTimeout(tokenRefresher);

    // Set the accessToken cookie.
    setAccessToken(
      response.data.access_token,
      response.data.expires_in
    );

    // Set the refreshToken cookie.
    setRefreshToken({
      refresh_token: response.data.refresh_token,
      grant_type
    });

    // Set a refresh request to fetch a new token right before this one expires.
    tokenRefresher = setTimeout(
      () => {
        // Make sure to get the current token.
        const refreshToken = getRefreshToken();

        if (refreshToken) {
          // If we have a refreshToken, use it.
          fetchRefreshToken(refreshToken)
        } else {
          // Otherwise stop trying.
          clearTimeout(tokenRefresher);
        }
      },
      // eslint-disable-next-line no-magic-numbers
      (response.data.expires_in - TOKEN_REFRESH_THRESHOLD) * 1000
    );

    pendingTokenRequest = null;

    return ({
      status: response.status,
      message: 'Login successful'
    })
  }
  throw new Error('Unable to authenticate');
}

export const handleTokenResponseError = error => {
  const message = get(error, 'response.data.message');
  // If a accessToken request failed, remove the token and retry with a refresh token.
  removeAccessToken();
  if (getRefreshToken()) {
    return getToken();
  }

  return get(error, 'error.response.data.message');
}

export const handleRefreshTokenResponseError = error => {
  console.log(error);
  pendingTokenRequest = null;
  logoutOauth();
}

const rejectCallbacks = {};

const runRejectCallbacks = () => {
  Object.values(rejectCallbacks).forEach(func => {
    func();
  });
}

export const subscribe = (id, func) => {
  rejectCallbacks[id] = func;
}

export const unsubscribe = id => {
  // eslint-disable-next-line prefer-reflect
  delete rejectCallbacks[id];
}

/**
 * Get the token asyncrhonously.
 *
 * By fetching the token asynchronously, we have the flexibility
 * to fetch a new token if we don't have one locally or if it has
 * expired.
 *
 * @returns {Promise}
 *   Will resolve with the token or reject with an error.
 */
export const getToken = () => new Promise((resolve, reject) => {
  const token = getAccessToken();
  const refreshToken = getRefreshToken();

  // Resolve an existing token.
  if (token) {
    resolve(token);

    return token;
  }

  // If we don't have a refresh_token, this session has expired.
  if (!refreshToken) {
    const error = new Error('Your session has expired!');

    // Remove sessiondata from the store;
    window.jocrfStore.clearAll();
    reject(error);
    runRejectCallbacks();
    return false;
  }

  fetchRefreshToken(refreshToken)
    .then(() => {
      resolve(getAccessToken());
    })
    .catch(error => {
      reject(error);
      runRejectCallbacks();
    })
});

/**
 * This should be called in a page, so that the location prop is available.
 * After it's set it will return the token from a cookie.
 *
 * @param {object} location
 *
 * @return {string} || false
 */
export const authTempAccess = location => {

  if (location && location.hasOwnProperty('search')) {
    const token = parseTempAccess(location.search)

    if (token) {
      // @todo validation
      setTempAccess(token)

      return token
    }

    return false
  }

  // Try to return the cookie value.
  return getTempAccess()
}


export const getClient = id => {
  const url = getUrl(routes.client, id)

  return axios.get(url)
}
