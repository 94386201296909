/**
 *  Context: location
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Create a Context
const DialogContext = React.createContext();

export const DialogProvider = ({ children, duration = 200 }) => {
  const [
    status,
    setStatus
  ] = useState(false);

  const [
    content,
    setContent
  ] = useState({
    heading: null,
    content: null
  });

  const value = {
    isOpen: status,
    duration,
    open: () => setStatus(true),
    close: () => {
      setStatus(false);

      setTimeout(() => {
        setContent({
          heading: null,
          content: null
        });
      }, duration);
    },
    toggle: () => setStatus(!status),
    heading: content.heading,
    content: content.content,
    openWith: options => {
      setContent(options);
      setStatus(true);
    }
  }

  return (
    <DialogContext.Provider value={value}>
      {children}
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.element
}

export default DialogContext
