export const dig = (obj, target) => {
  if (!obj) {
    return;
  }

  return target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
      if (acc !== undefined) return acc;
      if (typeof val === 'object') return dig(val, target);
    }, undefined);
}

/**
 * Given an array of object with {id, value}
 * returns an object with properties and values.
 * [{id: foo, value: bar}, {id: fug, value: ugly}]
 * {foo: bar, fug: ugly}
 *
 * @param Array list
 * @param String param
 *   The property within the list item that contains the id, value
 * @param String key
 *   The optional key name to use instead of value
 *
 * @return Object
 */
export const flatMap = (list, param = null, key = null) => (
  list.reduce((acc, current) => {
    const inner = param ? current[param] : current
    const name = key ? key : 'value'

    if (Array.isArray(inner)) {
      inner.forEach(item => {
        acc[item.id] = item[name]
      })
    } else {
      acc[inner.id] = inner[name]
    }

    return acc
  }, {})
)

export const mergeMap = (list, param = {}) => (
  list.map(item => Object.assign(item, param))
)

export const get = (tree, path, defaultValue = null) => path.split('.').reduce((branch, prop) => {
  return branch && branch.hasOwnProperty(prop)
    ? branch[prop]
    : defaultValue;
}, {...tree});

export const getLabelFromId = (options, id) => {
  const option = options.find(item => item.id === id);

  return option
    ? option.label
    : '';
}
