const qs = require('qs')

export const parseQuery = query => {
  return query
   ? qs.parse(query, { ignoreQueryPrefix: true })
   : false
}

export const parseTempAccess = query => {
  const parsed = parseQuery(query)

  return parsed && parsed.hasOwnProperty('access')
    ? parsed.access
    : false
}

// API routes
export const routes = {
  token: '/oauth/token',
  user: '/api/user',
  client: '/api/clients'
}

const apiary = [];

/**
 * Check if the route is root relative.
 * @param {String} route
 *  A url or path.
 * @returns {Boolean}
 */
const isRootRelative = route => route.indexOf('/') === 0;

/**
 * Get the backend API domain.
 * The OAUTH_CLIENT_URL environment
 * variable will take precendence.
 *
 * @returns {String}
 */
const getAPIDomain = () => {
  const origin = window.location.origin;

  return process.env.OAUTH_CLIENT_URL || origin.replace('//dashboard.', '//admin.');
}

// If a route is not ready in drupal, default to apiary
const getDomain = route => {
  // Only return a domain for root relative routes.
  if (isRootRelative(route)) {
    return apiary.indexOf(route) > -1
      ? "https://private-ff14e7-jocrf.apiary-mock.com"
      : getAPIDomain()
  }

  return '';
}

export const getRoute = name => (routes[name] ? routes[name] : null);

export const getUrl = (route, param) => (
  `${getDomain(route)}${route}${param ? `/${param}` : ''}`
)

export const internal = (clientId, orderId) => ({
  info: {
    intro: `client-info/${clientId}`,
    personal: `client-info/${clientId}/personal`,
    education: `client-info/${clientId}/education`,
    family: `client-info/${clientId}/family`,
    employment: `client-info/${clientId}/employment`,
    interests: `client-info/${clientId}/interests`,
    // Allow for redirect to selected order page
    payment: orderId === undefined
        ? `client-info/${clientId}/payment`
        : `client-info/${clientId}/payment/${orderId}`,
    complete: `client-info/${clientId}/complete`
  }
})
