import axios from 'axios'
import { getUrl } from './routes'
import { getToken, removeAccessToken } from './oauth'
import { get } from './../utils/objects'

// eslint-disable-next-line no-magic-numbers
export const responseIsOK = response => response.status < 400

export const formatError = error => {
  return Object.assign({
    status: console.log(error.message) || error.message
  }, error);
}

export const formatHeaders = token => (
  {
    Authorization: `Bearer ${token}`
  }
)

const makeRequest = (route, options, token) => axios(
  getUrl(route),
  {
    ...options,
    headers: formatHeaders(token),
  }
);

export const request = (route, options, retry = true) => new Promise(
  (resolve, reject) => {
    getToken()
      .then(token => (
        axios(
          getUrl(route),
          {
            ...options,
            headers: formatHeaders(token),
          }
        )
          .then(resolve)
          .catch(error => {
            // If the user doesn't have access, their token may have been
            // revoked. Remove the stored access token and try one more time.
            if (get(error, 'response.status') === 403 && retry) {
              removeAccessToken();
              request(route, options, false)
                .then(resolve)
                .catch(error => {
                  reject(formatError(error))
                });
            }
            else {
              reject(formatError(error))
            }
          })
      ))
      .catch(error => {
        reject(formatError(error))
      })
  }
);
