import React from 'react'

const SvgIcon = ({ children }) => (
  <svg className="field-text--prefix" width='8' height='13' viewBox='0 0 8 13'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    { children }
</svg>
)

const FieldText = ({ label, labelClass = 'field-text--label', className = 'field-text--input', placeholder = '', prefix = null, suffix = null, name, type = "text", step, value, onChange, onBlur, isValid = true, required, labelSuffix = null, ...props }) => (
  <div className="field-text">
    <label className={labelClass} htmlFor={name}>{label}{labelSuffix ? labelSuffix : ''}</label>
    <div className={className} data-valid={isValid}>
      {prefix === 'dollar' &&
        <SvgIcon>
          <path fill="#009432" d="M3.318,12.509 C3.253,12.448 3.22,12.366 3.22,12.264 L3.22,11.284 C2.548,11.218 1.972,11.055 1.491,10.794 C1.01,10.533 0.647,10.213 0.399,9.835 C0.152,9.457 0.019,9.053 0,8.624 C0,8.55 0.03,8.482 0.091,8.421 C0.152,8.361 0.224,8.33 0.308,8.33 L1.568,8.33 C1.68,8.33 1.766,8.351 1.827,8.393 C1.888,8.435 1.946,8.503 2.002,8.596 C2.105,8.913 2.322,9.179 2.653,9.394 C2.985,9.609 3.44,9.716 4.018,9.716 C4.662,9.716 5.15,9.606 5.481,9.387 C5.813,9.168 5.978,8.867 5.978,8.484 C5.978,8.232 5.894,8.022 5.726,7.854 C5.558,7.686 5.304,7.539 4.963,7.413 C4.622,7.287 4.121,7.135 3.458,6.958 C2.357,6.697 1.549,6.351 1.036,5.922 C0.523,5.493 0.266,4.877 0.266,4.074 C0.266,3.346 0.53,2.73 1.057,2.226 C1.585,1.722 2.305,1.414 3.22,1.302 L3.22,0.336 C3.22,0.233 3.253,0.152 3.318,0.091 C3.383,0.03 3.463,0 3.556,0 L4.424,0 C4.527,0 4.609,0.03 4.669,0.091 C4.73,0.152 4.76,0.233 4.76,0.336 L4.76,1.33 C5.367,1.414 5.887,1.594 6.321,1.869 C6.755,2.144 7.087,2.464 7.315,2.828 C7.544,3.192 7.668,3.547 7.686,3.892 C7.686,3.976 7.658,4.046 7.602,4.102 C7.546,4.158 7.476,4.186 7.392,4.186 L6.062,4.186 C5.866,4.186 5.731,4.102 5.656,3.934 C5.6,3.635 5.416,3.386 5.103,3.185 C4.79,2.984 4.401,2.884 3.934,2.884 C3.421,2.884 3.017,2.982 2.723,3.178 C2.429,3.374 2.282,3.659 2.282,4.032 C2.282,4.284 2.355,4.494 2.499,4.662 C2.644,4.83 2.877,4.979 3.199,5.11 C3.521,5.241 3.981,5.381 4.578,5.53 C5.409,5.717 6.069,5.927 6.559,6.16 C7.049,6.393 7.411,6.687 7.644,7.042 C7.878,7.397 7.994,7.849 7.994,8.4 C7.994,9.212 7.702,9.87 7.119,10.374 C6.536,10.878 5.75,11.181 4.76,11.284 L4.76,12.264 C4.76,12.366 4.73,12.448 4.669,12.509 C4.609,12.57 4.527,12.6 4.424,12.6 L3.556,12.6 C3.463,12.6 3.383,12.57 3.318,12.509" />
        </SvgIcon>
      }
      <input type={type} id={name} name={name} placeholder={placeholder} value={value} onChange={onChange} step={step} required={required} />
      {suffix && <span className="field-text--suffix">{suffix}</span>}
    </div>
  </div>
)

export default FieldText
