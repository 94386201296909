/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react';
import SvgIcon from './icon/svgIcon';
import { MessageError, MessageStatus } from './icon/icons';
import MessagesContext from '../context/messages';

const Messages = () => {
  const { messages, clearMessages } = useContext(MessagesContext);
  useEffect(() => {
    if (messages.length > 0) {
    const timer = setTimeout(() => {
      clearMessages()
    }, 5000)
    }
  }, [messages]);

  if (messages.length <= 0) {
    return null;
  }

  return (
    <div className="messages">
      {messages.map((message, key) => (
        <div className={`message message--${message.type}`} key={key}>
          <SvgIcon width="24" height="24" className="message__icon">
            {message.type === 'status' ? <MessageStatus/> : <MessageError/>}
          </SvgIcon>
          <p className="message__text">{message.text}</p>
        </div>
      ))}
    </div>
  )
}

export default Messages;
