import React, { useState } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'

import Button from './../button'
import Card from '../card'
import FieldText from '../field/formikText'
import Icon from './../icon'
import Messages from './../messages'
import { AuthConsumer } from './authContext'
import get from 'lodash/get';

const Login = () => {
  const [
    messages,
    setMessages
  ] = useState([]);

  return (
    <AuthConsumer>
      {({ login }) => (
        <div className="container-panel">
          <Card>
            <Formik
              initialValues={
                {
                  username: '',
                  password: ''
                }
              }
              onSubmit={(values, { setSubmitting, setErrors }) => {

                setSubmitting(true);
                login(values.username, values.password)
                  .then(response => {
                    setMessages([]);
                    setSubmitting(false)
                  })
                  .catch(error => {
                    setMessages([
                      {
                        type: 'error',
                        text: get(error, 'response.data.message')
                      }
                    ]);
                    setErrors({
                      username: 'Check Username',
                      password: 'Check Password'
                    })
                    setSubmitting(false)
                  })
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                <Form>
                  <div className="card--inner">
                    <h2 className="card--title">JOCRF</h2>
                    <Messages messages={messages} />
                    <div className="layout--fields-centered">
                      <div>
                        <Field
                          name="username"
                        >
                          {({ field, form }) => (
                            <FieldText type="text" {...field} isValid={!form.errors[field.name]} label="Username" />
                          )}
                        </Field>
                        <ErrorMessage name="username" render={msg => <div className="error">{msg}</div>} />
                      </div>
                      <div>
                        <Field
                          name="password"
                        >
                          {({ field, form }) => (
                            <FieldText type="password" {...field} isValid={!form.errors[field.name]} label="Password" />
                          )}
                        </Field>
                        <ErrorMessage name="password" render={msg => <div className="error">{msg}</div>} />
                      </div>
                    </div>
                  </div>

                  <div className="card--inner card--divider layout-add-submit">
                    <Button theme="primary" type="submit">
                      <Icon icon="client" theme="primary" modifier="next" />
                      <span disabled={isSubmitting} className="button__label">Login</span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      )}
    </AuthConsumer>
  )
}

export default Login
