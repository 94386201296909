import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './../scss/styles.scss'
import Auth from './auth'
import { DialogProvider } from './../context/dialog';
import { MessagesProvider } from '../context/messages'
import Dialog from './dialog'
import favicon from '../images/favicon.ico';

const Page = ({ authenticated = true, children }) => {
  const Wrapper = authenticated
    ? Auth
    : props => (<>{props.children}</>);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' }
            ]}
          >
            <html lang="en" />
            <link rel="icon" href={favicon} />
          </Helmet>
            <DialogProvider>
              <MessagesProvider>
                <Wrapper>
                  <>
                    {children}
                    <Dialog />
                  </>
              </Wrapper>
            </MessagesProvider>
          </DialogProvider>
        </>
      )}
    />
  );
}

Page.propTypes = {
  children: PropTypes.node
}

export default Page
