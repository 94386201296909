import React from 'react'
import qs from 'qs';
import { globalHistory } from "@reach/router"

import { AuthProvider, AuthConsumer } from './authContext'
import Login from './login'
import LoginWithAuthCode from './loginWithAuthCode';
import LoadingIndicator from '../LoadingIndicator';

const QUEARY_PARAM_CODE = 'code';

const Auth = ({ children }) => {
  // If an authcode is present in the url, use it.
  const code = qs.parse(globalHistory.location.search, { ignoreQueryPrefix: true })[QUEARY_PARAM_CODE];
  const redirect_uri = `${globalHistory.location.origin}${globalHistory.location.pathname}`.replace(/\/+$/, "");

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ authState }) => {
          switch (authState) {
            case 'isPending':
              return (<LoadingIndicator fullScreen={true} />)
            case 'isTrue':
              // Force reauthentication if code is present.
              // This is necessary to allow one time login links to work
              // even the user is already logged in with a different account.
              return code
                ? (<LoginWithAuthCode
                  code={code}
                  redirect_uri={redirect_uri}
                />)
                : children;
            default:
              return code
                ? (<LoginWithAuthCode
                  code={code}
                  redirect_uri={redirect_uri}
                />)
                : (<Login />)
          }}
        }
      </AuthConsumer>
    </AuthProvider>
  );
}

export default Auth
