import React from 'react'

import SvgIcon from './svgIcon'

import {
  plus,
  swap,
  next,
  remove
} from './modifiers'

import {
  accounting,
  deposit,
  client,
  arrow,
  arrowCircle,
  arrowCircleDown,
  arrowCircleUp,
  check,
  move,
  forward,
  close,
  filter,
  research,
  summary,
  note,
  info,
  download,
  search,
  calendar,
  envelope,
  time,
  folder,
  office,
  education,
  family,
  employment,
  hobbies,
  complete,
  lock,
  appt
} from './icons'


const icons = {
  plus,
  swap,
  remove,
  next,
  accounting,
  deposit,
  client,
  arrow,
  arrowCircle,
  arrowCircleDown,
  arrowCircleUp,
  check,
  move,
  forward,
  close,
  filter,
  research,
  summary,
  note,
  info,
  download,
  search,
  calendar,
  envelope,
  time,
  folder,
  office,
  education,
  family,
  employment,
  hobbies,
  complete,
  lock,
  appt
}

const draw = (icon, theme, value) => (
  icons.hasOwnProperty(icon)
    ? icons[icon](theme, value)
    : <rect fill="#FFF" x="0" y="0" width="24" height="24" rx="3"></rect>
)

const Icon = ({ icon, theme, value = '', modifier = null, onClick = null }) => (
  <div
    className={`
      icon icon--${icon} ${theme ? `icon__${theme}` : ''} ${modifier
    ? 'icon__has-modifier'
    : ''}
    `}
    onClick={onClick}>
    <SvgIcon width="24" height="24" label={icon}>
      {draw(icon, theme, value)}
    </SvgIcon>
    {modifier && (
      <SvgIcon
        width="24"
        height="24"
        label="modifier"
      >{draw(modifier, theme)}</SvgIcon>
    )}
  </div>
);

export default Icon
