import React, { useContext } from 'react'
import Card from '../card'
import Icon from '../icon'
import DialogContext from '../../context/dialog';
import { CSSTransition } from 'react-transition-group';

const Dialog = ({
  children,
  onCloseClick
}) => {
  const dialog = useContext(DialogContext);
  const { heading, content, duration } = dialog;

  const onClose = () => {
    if (onCloseClick) {
      onCloseClick();
    }

    dialog.close();
  }

  return (
    <CSSTransition
      in={dialog.isOpen}
      timeout={duration}
      classNames="dialog-">
    <div className={"dialog"}>
      <div className="dialog__inner calendar-add-dialog">
        <Card modifier="card--panel dialog-pane">
          <Icon icon="close" onClick={onClose} />
          {heading && <h2>{heading}</h2>}
          {content}
          {children}
        </Card>
      </div>
    </div>
    </CSSTransition>
  )
};

export default Dialog
